<template>
  <div>
    <PageHeader fileName="header-9" withTablet withMobile>
      <h3>{{ $lang("title") }}</h3>
    </PageHeader>

    <section class="py-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 d-flex flex-column">
            <h2 class="mb-4" v-html="$lang('header.title')" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ $lang("header.paragraph1") }}
            </h6>
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ $lang("header.paragraph2") }}
            </h6>
          </div>
        </div>
      </div>
    </section>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row mb-7">
          <div class="col-lg-4 d-flex flex-column">
            <h4 class="text-tertiary mb-4">
              {{ $lang("spanish_test.title") }}
            </h4>

            <IconCard
              icon="list"
              theme="tertiary"
              class="mt-auto mb-6 mb-lg-0 h-auto"
              to="https://docs.google.com/forms/d/e/1FAIpQLSdNSCvkVLpHJ2xYxzTBJu_B_Oigg98mJxPtzSDTp2gbOp3hpQ/viewform"
            >
              <h6>{{ $lang("spanish_test.exam_cta") }}</h6>
            </IconCard>
          </div>
          <div class="col-lg-6 offset-lg-2">
            <h6 class="fw-300 lh-3 mb-5">
              {{ $lang("spanish_test.description") }}
            </h6>

            <ul
              class="disc-style disc-tertiary fw-300 d-flex flex-column gap-4"
            >
              <li>{{ $lang("spanish_test.instruction1") }}</li>
              <li>
                {{ $lang("spanish_test.instruction2") }}
              </li>
              <li>
                {{ $lang("spanish_test.instruction3") }}
              </li>
              <li>
                {{ $lang("spanish_test.instruction4") }}
              </li>
              <li>
                {{ $lang("spanish_test.instruction5") }}
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 order-1 order-lg-0">
            <h6 class="fw-300 lh-3 mb-5">
              {{ $lang("english_test.description") }}
            </h6>

            <ul
              class="disc-style disc-secondary fw-300 d-flex flex-column gap-4 offset-lg-1"
            >
              <li>{{ $lang("english_test.instruction1") }}</li>
              <li>
                {{ $lang("english_test.instruction2") }}
              </li>
              <li>
                {{ $lang("english_test.instruction3") }}
              </li>
              <li>
                {{ $lang("english_test.instruction4") }}
              </li>
              <li>
                {{ $lang("english_test.instruction5") }}
              </li>
            </ul>
          </div>
          <div class="col-lg-5 offset-lg-1 d-flex flex-column">
            <h4 class="text-secondary mb-4 text-lg-right">
              {{ $lang("english_test.title") }}
            </h4>

            <IconCard
              icon="list"
              theme="secondary"
              class="mt-auto mb-6 mb-lg-0 h-auto"
              to="https://docs.google.com/forms/d/e/1FAIpQLSfwuDtDS4d0ceP5wSHPQCOHdW2I9VdJO0VLeX9Uo-GCDqoZlA/viewform"
            >
              <h6>{{ $lang("english_test.exam_cta") }}</h6>
            </IconCard>
          </div>
        </div>
      </div>
    </section>

    <section class="py-7 bg-tertiary">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("footer.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card1.title')"
              :to="{ name: 'methodology', hash: '#levels' }"
            >
              <small class="m-0">
                {{ $lang("footer.card1.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card2.title')"
              :to="{ name: 'our_courses' }"
            >
              <small class="m-0">
                {{ $lang("footer.card2.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card3.title')"
              :to="{ name: 'student_visa' }"
            >
              <small class="m-0">
                {{ $lang("footer.card3.body") }}
              </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-8" theme="tertiary">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import IconCard from "@/components/IconCard.vue";
import CardButton from "@/components/CardButton.vue";

export default {
  components: {
    CardButton,
    PageHeader,
    ContactFormSection,
    IconCard,
  },
  data: () => ({}),
};
</script>

<style></style>
